<template>
  <div class="progress_parent">
    <div v-for="(skill, index) in skills" :key="index">
      <Progress
        :transitionDuration="5000"
        :radius="50"
        :strokeWidth="6"
        :value="skill.percent"
        strokeColor="#d63031"
      >
        <template v-slot:footer>
          <b>{{ skill.name }}</b>
        </template>
      </Progress>
    </div>
  </div>
</template>
<script>
import Progress from "easy-circular-progress";

export default {
  name: "app",
  components: {
    Progress,
  },
  data () {
    return {
      skills: [
        {
          name: 'HTML & Css',
          percent: '80'
        },
        {
          name: 'Javascript',
          percent: '80'
        },
        {
          name: 'Typescript',
          percent: '75'
        },
        {
          name: 'jQuery',
          percent: '80'
        },
        {
          name: 'Nuxt',
          percent: '75'
        },
        {
          name: 'VueJs',
          percent: '85'
        },
        {
          name: 'VueX & Pinia',
          percent: '90'
        },
        {
          name: 'Vue Router',
          percent: '90'
        },
        {
          name: 'Vuetify',
          percent: '90'
        },
        {
          name: 'Quasar',
          percent: '80'
        },
        {
          name: 'Bootstrap 5',
          percent: '75'
        },
        {
          name: 'Tailwind',
          percent: '80'
        },
        {
          name: 'Git',
          percent: '50'
        },
        {
          name: 'PWA',
          percent: '60'
        }
      ]
    }
  }
};
</script>

<style scoped>
.progress_parent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.progress_parent > div {
  width: 170px;
  text-align: center;
  height: 200px;
  display: flex;
  line-height: 50px;
  justify-content: center;
  align-items: center;
}
</style>
