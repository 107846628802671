<template>
  <div class="experience_content col-12 col-md-10 offset-md-1">
    <div class="col-12" v-if="!isCertification">
      <ExperienceItem
        v-for="experience in experiences"
        :data="experience"
      ></ExperienceItem>
    </div>

    <div class="col-12" v-if="isCertification">
      <CertificationItem
        v-for="certification in certifications"
        :data="certification"
      ></CertificationItem>
    </div>
  </div>
</template>
<script>
import CertificationItem from "./CertificationItem.vue";
import ExperienceItem from "./ExperienceItem.vue";

export default {
  props: {
    isCertification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      experiences: [
        {
          jobTitle: "Front-end developer",
          companyName: "iToll",
          companyDescription: "An integrated vehicle management system ",
          iconClass: "fa-briefcase",
          href: "https://itoll.com/",
          linkName: "itoll.com",
          desciption:
            "As a front-end developer at iToll, I actively participated in maintaining and developing core sections of the website, including authentication, profiles, and ticketing. With a user base exceeding 9 million, I focused on enhancing performance for high-traffic pages and improving core web vitals scores. Additionally, our team have successfully migrated the project from Nuxt 2 and Vuex to Nuxt 3 and Pinia, while we simultaneously developed new features. My expertise allowed me to effectively contribute to the growth and optimization of Itoll's platform.",
        },
        {
          jobTitle: "Front-end developer",
          companyName: "IdPay",
          companyDescription:
            "A fintech startup with personal payment gateway ",
          iconClass: "fa-briefcase",
          href: "https://panel.idpay.ir/",
          linkName: "panel.idpay.ir",
          desciption:
            "During my time at the company, I successfully worked on four different projects, showcasing my expertise in refactoring, maintaining, and launching new initiatives. These projects include the Riyal payment gateway, a crypto payment gateway, a web mobile application for payment tools, and a digital currency exchange. In these projects, I applied key technologies and frameworks such as Vue.js, Nuxt, TypeScript, and TailwindCSS. My responsibilities ranged from modifying front-end APIs to building features for mobile-optimized applications and custom component development.Throughout these experiences, I gained valuable expertise in working with modern front-end tools and workflows, delivering a diverse range of products.",
        },
        {
          jobTitle: "Front-end developer",
          companyName: "Tejarat Shayan",
          companyDescription:
            "Financial and banking services in the digital ecosystem",
          iconClass: "fa-briefcase",
          desciption:
            "During this role, I was responsible for developing a financial admin panel using Vue3 with the Composition API. An important challenge was implementing a micro-frontends approach for code organization, which required self-study of bundling tools like Webpack and module federation. I also researched newer state management libraries such as Pinia to replace Vuex. Additionally, I studied 'behind the scenes' JavaScript concepts to gain a deeper understanding of code. Through taking on this project, I gained valuable experience developing an admin interface using the latest Vue features while enhancing my understanding of modern JavaScript frameworks, tools, and application architecture best practices.",
        },
        {
          jobTitle: "Front-end developer",
          companyName: "FreightHall",
          companyDescription: "Communication Platform for supply chain teams",
          iconClass: "fa-briefcase",
          href: "https://merchain.io/",
          linkName: "merchain.io",
          desciption:
            "As a front-end developer, I was responsible for developing a website and admin panel using Nuxt2 and Vue2 for a client, which included learning Nuxt and using fetch and asyncData to retrieve backend data, implementing necessary SEO meta tags, customizing CSS stylesheets, handling authentication using the Nuxt auth library. I faced challenges in utilizing newer JS6 methods to make the admin panel fully dynamic and dependent on user-generated data. Key achievements included implementing atomic design patterns in Vue2, properly leveraging Vuex for state management across pages, creating reusable concept enums in mixins, utilizing mixins to improve code quality with general functions, and handling authentication using the Nuxt auth library. Through these efforts, I successfully developed a feature-rich admin interface and website for the client that met SEO, design, and authentication requirements.",
        },
        {
          jobTitle: "Front End Developer",
          companyName: "KandaIdea",
          companyDescription:
            "Projects in field of GIS, Geomatics, marine solutions and ship tracking",
          iconClass: "fa-briefcase",
          href: "http://kandaidea.com/",
          linkName: "kandaidea.com",
          desciption:
            "During my tenure, I excelled in developing a fully responsive corporate English website using HTML5, CSS3, jQuery, and Wow.js. My responsibilities included custom coding layouts and stylesheets, implementing dynamic elements like parallax images, scrolling effects, and customized sliders. Modern layout methods such as Flexbox and Grid were employed to ensure automatic adaptation based on screen size. By following best practices, including semantic HTML modules and thorough browser testing, I ensured seamless functionality across Chrome, Firefox, Safari, and Edge. Additionally, I successfully integrated location and asset data onto interactive maps for clients websites using the OpenLayers library. This involved developing customizable map tools and a dynamic menu using JavaScript and jQuery. I utilized AJAX calls to retrieve data from backend APIs and overcame the challenge of avoiding duplicated code by creating a jQuery plugin for dynamic generation of reusable menu items. Through DOM manipulation methods like append(), I programmatically generated HTML elements, enabling the creation of adaptable menu items, layers, and tools. My expertise in front-end development, responsive design, and data integration allowed me to deliver exceptional solutions that met the clients' requirements.",
        },
      ],
      certifications: [
        {
          date: "2019 - 2020",
          jobTitle: "Web Pack",
          companyName: "Tehran Technical Complex",
          iconClass: "fa-graduation-cap",
          tasks: [
            {
              desciption:
                " I learned HTML, CSS, Javascript and jQuery in the Tehran Technical Complex, then I read and learned Vue js myself and learned nuxt at work.",
            },
          ],
        },
        {
          date: " 2013 - 2017",
          jobTitle: "Bachelor of Chemical Engineering",
          companyName: "Shahid Bahonar University",
          iconClass: "fa-graduation-cap",
          tasks: [
            {
              desciption:
                "I studied chemical engineering at Kerman University for four years.",
            },
          ],
        },
      ],
    };
  },
  components: { ExperienceItem, CertificationItem },
};
</script>

<style scoped>
.experience_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 50px;
}
</style>
