<template>
  <div id="about_page">
    <div class="content">
      <section class="head_text title-section text-left text-sm-center">
        <h1>ABOUT <span>ME</span></h1>
        <span class="title-bg d-none d-sm-block">Resume</span>
      </section>

      <div class="info_aboutme col-10 offset-1 col-lg-6 offset-lg-3 mt-5">
        <p>
          I'm Matin Aliakbari, born in 1995 and live in Tehran. I graduated in
          chemical engineering from Bahonar University of Kerman, but due to my
          interest in computer and programming, after graduating, I took web
          programming courses under the supervision of eminent teachers in this
          field.
          <br />
          I love programming and my work and I try to make more progress in this
          field.
        </p>
      </div>
      <hr class="separator" />
      <div class="skills">
        <div class="head_skills">
          <h3>My Skills</h3>
        </div>
        <div class="col-12 col-lg-10 offset-lg-1">
          <app-progress></app-progress>
        </div>
      </div>
      <hr class="separator" />
      <div class="experience">
        <div class="head_skills">
          <h3>My EXPERIENCE</h3>
        </div>
        <ExperienceListVue></ExperienceListVue>
      </div>
      <div class="experience">
        <div class="head_skills">
          <h3>My Certification</h3>
        </div>
        <ExperienceListVue is-certification></ExperienceListVue>
      </div>
    </div>
  </div>
</template>

<script>
import appProgress from "../ProgressBar/Progress.vue";
import ExperienceListVue from "./ExperienceList.vue"
export default {
  components: {
    appProgress,
    ExperienceListVue
  },
};
</script>
<style scoped>
#about_page {
  width: 100%;
  height: 100vh;
  float: left;
}
.content {
  min-height: 100%;
}
.info_aboutme {
  line-height: 2.2;
  text-align: justify;
  margin-bottom: 60px;
}
.head_skills > h3 {
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
}
.experience {
  padding-bottom: 55px;
}

/* .experience_content > div > p::after {
    content: "";
    position: absolute;
    top: 0;
    left: 40px;
    z-index: -1;
    bottom: 0px;
    border-left: 1px solid #d63031;
  }
  .experience_content > div > p > span {
    font-size: 11px;
    color: rgb(160, 159, 159);
  } */
</style>
