<template>
  <div class="col-md-12 px-0 mb-3">
    <div class="icon">
      <i class="fa" :class="data.iconClass"></i>
    </div>
    <span class="date"> {{ data.date }} </span>
    <h5 class="title">
      <span>
        {{ data.jobTitle }}
      </span>
      <span> -</span>
      <span>{{ data.companyName }}</span>
    </h5>
    <div class="redLine" v-for="(task, index) in data.tasks" :key="index">
      <p class="description">
        <span>{{ task.desciption }}</span>
        <a
          v-if="task.href"
          :href="task.href"
          target="-blanck"
          style="color:#4d8acb !important"
        >
          {{ `(${task.linkName})` }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style scoped>
.date {
  margin-left: 10px;
  color: #ddd;
  font-size: 13px;
}
.title {
  margin: 5px 65px;
}
.description {
  margin: 20px 65px 0;
  font-size: 16px;
  color: #ddd;
}
.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d63031;
  display: inline-block;
  text-align: center;
  line-height: 50px;
}
.icon > i {
  color: white;
}

.redLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 24px;
  z-index: -1;
  bottom: 0px;
  border-left: 1px solid #d63031;
}
.redLine > span {
  font-size: 11px;
  color: rgb(160, 159, 159);
}
@media screen and (max-width: 600px) {
  .title {
    margin: 5px 0 0 40px;
  }
  .description {
    margin: 20px 0 0 40px;
  }
}
</style>
