<template>
  <div class="col-md-12 px-0 mb-5">
    <div class="icon">
      <i class="fa" :class="data.iconClass"></i>
    </div>
    <h4 class="title job">
      {{ data.jobTitle }}
    </h4>
    <h6 class="title company">
      <span>{{ data.companyName }}</span>
      <a
          v-if="data.href"
          :href="data.href"
          target="-blanck"
          style="color:#4d8acb !important"
        >
          {{ `(${data.linkName})` }}
        </a>
    </h6>
    <div class="company-desc">
      <p>
        {{ data.companyDescription }}
      </p>
    </div>
    <div class="redLine">
      <p class="description">
        <span>{{ data.desciption }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style scoped>
.date {
  margin-left: 10px;
  color: #ddd;
  font-size: 13px;
}
.title {
  margin: 5px 65px;
}
.job{
  color: #d4d4d4;
  margin-top: -40px;
}
.company{
  color: #aeaeae;
}
.company > a {
  margin-left: 4px;
}
.company-desc{
  color: gray;
  font-size: 14px;
  font-style: italic;
  margin: 5px 65px;
}
.description {
  margin: 20px 65px 0;
  font-size: 16px;
  color: #ddd;
}
.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d63031;
  display: inline-block;
  text-align: center;
  line-height: 50px;
}
.icon > i {
  color: white;
}

.redLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 24px;
  z-index: -1;
  bottom: 0px;
  border-left: 1px solid #d63031;
}
.redLine > span {
  font-size: 11px;
  color: rgb(160, 159, 159);
}
@media screen and (max-width: 600px) {
  .title {
    margin: 5px 0 0 40px;
  }
  .company-desc {
    margin: 5px 0 0 40px;
  }
  .description {
    margin: 20px 0 0 40px;
  }
}
</style>
